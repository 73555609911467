import React, { Component } from "react";
import { Icon, TextField } from "@material-ui/core";
import moment from "moment";

import {
    UIPane,
    UIPaneContents,
    Section,
    UIPaneHeader,
    BlueButton,
} from "legacy-js/react/components/UiComponents";
import Loadable from "legacy-js/react/components/Loadable";
import Avatar from "legacy-js/react/components/Avatar";
import { BlueSwitch } from "legacy-js/react/components/Switch";
import {
    ShowDialog,
    BeautifulDialog,
} from "legacy-js/react/components/Dialogs/BaseDialog";
import AddTagsDialog from "legacy-js/react/views/UserOptions/dialogs/AddTagsDialog";
import { FlexBox, ScrollBox } from "legacy-js/react/components/LayoutGrid";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import styled from "styled-components";
import { _ } from "legacy-js/vendor";
import Tooltip from "legacy-js/react/views/PPTAddin/Components/Tooltip";
import { SlideThumbnail } from "legacy-js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import getUserProfile from "js/core/services/userProfiles";
import TagsList from "legacy-js/react/components/TagsList";

const PreviewContainer = styled.div`
    position: relative;
    width: 640px;
    height: 360px;
    background-color: #E0E0E0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .preview-button-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .replace-button {
            opacity: 0;
        }
    
        &:hover {
            .replace-button {
                opacity: 1;
            }
        }
    }
`;

const StatsContainer = styled.div`
  background: #f1f1f1;   
  width: 100%;
  padding: 15px;
  font-weight: 600;
  .MuiIcon-root {
    margin-right: 10px;
  }
  & > div + div {
    margin-top: 10px;
  }
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  label {
    font-size: 13px;
    width: 80px;
  }
  .avatar {
    margin-right: 10px;
  }
  .name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .date { 
    margin-left: 20px;
    font-size: 14px;
    color: #666;
  }
`;

export class TeamTemplateSettingsDialog extends Component {
    editOnClose = false;

    constructor(props) {
        super(props);

        const item = props.item;
        item.tags = item.tags || [];

        this.state = {
            delta: {
                tags: [...(item.tags)],
            },
            item,
            createdBy: {},
            modifiedBy: {},
        };
    }

    componentDidMount = async () => {
        const item = this.state.item;
        const createdBy = await getUserProfile(item.createdById);
        let modifiedBy = createdBy;
        if (item.createdById !== item.modifiedById) {
            modifiedBy = await getUserProfile(item.modifiedById);
        }
        this.setState({
            createdBy,
            modifiedBy,
        });
    }

    componentWillUnmount = async () => {
        let {
            updateTemplate,
            editTemplate,
        } = this.props;
        let {
            item,
            delta,
        } = this.state;

        // Remove redundant changes
        for (let key in delta) {
            if (_.isEqual(delta[key], item[key])) {
                delete delta[key];
            }
        }

        updateTemplate && await updateTemplate(delta);
        this.editOnClose && editTemplate && await editTemplate(item);
    }

    editTemplate = () => {
        const { closeDialog } = this.props;

        this.editOnClose = true;
        if (closeDialog) closeDialog();
    }

    handleDeltaChange = (name, value) => {
        const {
            delta,
        } = this.state;

        delta[name] = value;

        this.setState({
            delta,
        });
    }

    handleRemoveTag = tag => {
        const {
            delta,
        } = this.state;
        const {
            tags,
        } = delta;

        if (tags) {
            let index = tags.indexOf(tag);
            if (index > -1) {
                tags.splice(index, 1);
                this.setState({
                    delta,
                });
            }
        }
    }

    handleAddTag = () => {
        const { existingTags } = this.props;
        const { delta } = this.state;

        ShowDialog(AddTagsDialog, {
            activeTags: delta.tags,
            existingTags,
            acceptCallback: newTags => {
                const { delta } = this.state;
                delta.tags = newTags;
                this.setState({
                    delta,
                });
            },
        });
    }

    render() {
        const {
            closeDialog,
        } = this.props;
        const {
            item,
            delta,
            createdBy,
            modifiedBy,
        } = this.state;

        let fields = {
            ...item,
            ...delta,
        };

        let {
            name,
            description,
            createdAt,
            modifiedAt,
            ratedByCount = 0,
            tags = [],
            isPublished,
            sips,
        } = fields;

        let slideId = sips[0];

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={closeDialog} closeButton>
                <UIPane style={{ overflow: "hidden" }}>
                    <UIPaneHeader>Team Template Settings</UIPaneHeader>
                    <ScrollBox>
                        <UIPaneContents style={{ minHeight: 200 }}>
                            <Loadable isLoading={false}>
                                <Section>
                                    <Gap10 />
                                    <FlexBox top left>
                                        <PreviewContainer>
                                            <SlideThumbnail slideId={slideId} highQuality />
                                            <div className="preview-button-container">
                                                <BlueButton
                                                    className="replace-button"
                                                    onClick={this.editTemplate}
                                                >Edit Slide...</BlueButton>
                                            </div>
                                        </PreviewContainer>
                                        <Gap30 />
                                        <FlexBox column top left width={500}>
                                            <TextField
                                                autoFocus
                                                id="library-name"
                                                label="Name"
                                                fullWidth
                                                value={name}
                                                onChange={event => this.handleDeltaChange("name", event.target.value)}
                                                onBlur={event => this.handleDeltaChange("name", event.target.value)}
                                            />
                                            <Gap30 />

                                            <UserInfo>
                                                <label>Created by</label>
                                                <Avatar url={createdBy.photoURL} />
                                                <div className="name">
                                                    <span className="fullName">
                                                        {
                                                            createdBy.displayName ||
                                                            createdBy.email ||
                                                            <Tooltip title="This data predates our tracking of asset changes." arrow>
                                                                <b>Unknown</b>
                                                            </Tooltip>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="date">{moment.utc(createdAt).local().format("LL")}</div>
                                            </UserInfo>
                                            <Gap20 />
                                            {
                                                modifiedBy &&
                                                <UserInfo>
                                                    <label>Modified by</label>
                                                    <Avatar url={modifiedBy.photoURL} />
                                                    <div className="name">
                                                        <span className="fullName">
                                                            {
                                                                modifiedBy.displayName ||
                                                                modifiedBy.email ||
                                                                <Tooltip title="This data predates our tracking of asset changes." arrow>
                                                                    <b>Unknown</b>
                                                                </Tooltip>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="date">{moment.utc(modifiedAt).local().format("LL")}</div>
                                                </UserInfo>
                                            }

                                            <Gap20 />

                                            <StatsContainer>
                                                <FlexBox left middle>
                                                    <Icon>star</Icon>
                                                    {ratedByCount} likes
                                                </FlexBox>
                                            </StatsContainer>

                                            <Gap20 />
                                            <BlueSwitch
                                                id="hideSwitch"
                                                checked={isPublished}
                                                onChange={() => this.handleDeltaChange("isPublished", !isPublished)}
                                                label="Published"
                                            />

                                        </FlexBox>
                                    </FlexBox>

                                    <Gap30 />
                                    <TextField
                                        multiline
                                        id="description"
                                        label="Add a description or instructions..."
                                        fullWidth
                                        value={description}
                                        onChange={event => this.handleDeltaChange("description", event.target.value)}
                                        onBlur={event => this.handleDeltaChange("description", event.target.value)}
                                    />

                                </Section>
                                <Section>
                                    <FlexBox left middle fillWidth>
                                        <div className="section-title">Tags</div>
                                        <Gap20 />
                                        <TagsList
                                            tags={tags}
                                            onRemove={this.handleRemoveTag}
                                            onClick={this.handleAddTag}
                                        />
                                    </FlexBox>
                                </Section>
                            </Loadable>
                        </UIPaneContents>
                    </ScrollBox>
                </UIPane>
            </BeautifulDialog>
        );
    }
}
