import { _ } from "legacy-js/vendor";
import React from "reactn";
import { renderReactRootJSX } from "legacy-js/react/renderReactRoot";
import { Authenticate } from "legacy-js/react/views/Auth/Authenticate";
import { FirebaseUserConsumer } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { ds } from "js/core/models/dataService";
import { User } from "legacy-js/core/models/user";
import { app } from "js/namespaces";

import "css/dialogs.scss";
import * as analytics from "legacy-js/analytics";

class SuccessProxy extends React.Component {
    async componentDidMount() {
        const {
            firebaseUser,
            onSuccess
        } = this.props;

        app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
        await ds.setup();
        await app.user.load();
        await analytics.identify(app.user.getAuthUser());
        onSuccess && onSuccess(firebaseUser);
    }

    render() {
        return "Loading...";
    }
}

export const mountAuth = ({
    onSuccess,
    allowClose = true,
}) => {
    const body = document.querySelector("body");
    const container = document.createElement("div");
    container.id = "bai-auth-mount";
    container.style = "position: absolute; z-index: 1000;";
    body.append(container);

    const closeDialog = () => allowClose && container.remove();
    const onAuthSuccess = user => {
        container.remove();
        onSuccess && onSuccess(user);
    };

    const jsx = (
        <Authenticate
            defaultPage="signIn"
            popup
            skipReload
            backdropVisible
            onClose={closeDialog}
        >
            <FirebaseUserConsumer>{firebaseUser => (
                <SuccessProxy
                    firebaseUser={firebaseUser}
                    onSuccess={onAuthSuccess}
                />
            )}</FirebaseUserConsumer>
        </Authenticate>
    );

    renderReactRootJSX(jsx, container);
};
