import React, { Component } from "react";

import { ds } from "js/core/models/dataService";

import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import AppController from "legacy-js/core/AppController";

import { ShowUpgradeDialog } from "./Dialogs/BaseDialog";

import { withFirebaseUser } from "../views/Auth/FirebaseUserContext";

class ProBadge extends Component {
    constructor(props) {
        super(props);

        const { upgradeType, analytics, workspaceId } = props;

        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }

        if (!analytics) {
            throw new Error("analytics is required");
        }

        if (analytics && !analytics.cta) {
            throw new Error("analytics.cta is required");
        }

        this.state = {
            upgradeType: upgradeType || UpgradePlanDialogType.UPGRADE_PLAN
        };
    }

    handleClick = event => {
        const { beforeShowDialogCallback, analytics, workspaceId, closeDialog = () => {}, firebaseUser } = this.props;
        const { upgradeType } = this.state;

        if (upgradeType === UpgradePlanDialogType.TEAM_NOOP) return;
        event.stopPropagation();

        if (beforeShowDialogCallback) {
            beforeShowDialogCallback();
        }

        ShowUpgradeDialog({
            closeDialog: result => {
                // if checkout was successful, initialize the user
                if (result) {
                    ds.checkoutSetup();

                    AppController.initializeUser(firebaseUser).then(() => {
                        closeDialog(result);
                    });
                }
            },
            type: upgradeType,
            analytics: analytics,
            workspaceId: workspaceId
        });
    }

    render() {
        const { style, show } = this.props;
        const { upgradeType } = this.state;
        const isTeamBadge = upgradeType === UpgradePlanDialogType.UPGRADE_PLAN_TO_TEAM || upgradeType === UpgradePlanDialogType.TEAM_NOOP;
        if (show) {
            const plan = isTeamBadge ? "Team" : "Pro";
            return (
                <div
                    className="pro-badge" style={style || {}}
                    onClick={this.handleClick}
                >{plan.toUpperCase()}</div>
            );
        } else {
            return null;
        }
    }
}

export default withFirebaseUser(ProBadge);
